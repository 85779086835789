actionFlag = false;
actionFlag_timer = 500;	
function actionFlagUp(){actionFlag = true; setTimeout(function(){ actionFlag = false;}, actionFlag_timer);}
uiState = [];


$(document).ready(function(){
	//$(".scrollbar-inner").scrollbar();
	fixTopSliderPosts();
	checkTouchHover();
	
	$(".linkbox").click(function(e){
		e.preventDefault();
		var href = $(this).attr('data-href');
		window.location.href = href;
	});
	
	$(".lazy").lazyload({
	  effect : "fadeIn",
	  effectspeed : 200 
	});


	$('.radiobox-custom .radio_or_checkbox').checkator();
	
	/*
	$('.postLst').masonry({
	  itemSelector: '.box-post-list',
	});
	*/

	fixUI();
	fixHeader();
	setTimeout(function(){ 	fixUI(); fixHeader(); }, 200);
	/*
	$("#header .wide #btn-left").bind('click touchstart',function(){if (!actionFlag) {actionFlagUp();
		toggleLeftContainer();
	}});
	*/
	$("#header .wide .btn-navi").click(function(e){
		e.stopPropagation();
	});
	$("#header .wide .btn-navi").bind('click touchstart',function(e){if (!actionFlag) {actionFlagUp();
		//e.stopPropagation();
	}}).mouseover(function(){
		$(this).find(".navi-ico").removeClass('fa-sort-up');
		$(this).find(".navi-ico").addClass("fa-sort-down");
		$(this).find(".navi-ico-box").css("margin-top","-10px");
		var naviID = $(this).attr("data-navi");
		//$("#navi-sub-" + naviID).slideDown(100);
	}).mouseleave(function(){
		$("#header .wide .btn-navi .navi-ico").removeClass('fa-sort-down');
		$("#header .wide .btn-navi .navi-ico").addClass('fa-sort-up');		
		$("#header .wide .btn-navi .navi-ico-box").css("margin-top","");				
	});
	
	$("#header").mouseleave(function(){
		$(".navi-sub").slideUp(150);		
	});	
	
	$("#header .narrow #btn-left").bind('click touchstart',function(){if (!actionFlag) {actionFlagUp();
		toggleLeftSidebar();
	}});
	
	$("#header .narrow #btn-right").bind('click touchstart',function(){if (!actionFlag) {actionFlagUp();
		//toggleRightSidebar();
	}});

	
	$("#header .btn-show-search").bind('click touchstart',function(){if (!actionFlag) {actionFlagUp();
		toggleSearch();
	}});	
	$("#dismiss").bind('click touch', function(){
		if (!actionFlag) {actionFlagUp();
			dismiss();

		}
	});
	
	setTimeout(function(){ $(this).scrollTop(0); }, 100);
	
	$(".search-keyword").bind('keypress', function(e) {
		if (e.which == 13) {/* 13 == enter key@ascii */
			var searchKeyword = $(this).val();
			searchAction(searchKeyword);
		}
	});
	
	$(".btn-action-search").bind('click', function(e) {
		var searchKeyword = $(this).parent().parent().find(".search-keyword").val();
		searchAction(searchKeyword);
	});	
	
	$(".popup").click(function(e){
		e.preventDefault();
		
		var url = $(this).attr("href");
		var title = $(this).attr("data-title");
		var width = $(this).attr("data-width");
		var height = $(this).attr("data-height");			
		$.popupCenter(url, title, width, height);
	});
	
	$(".sidebar-category .sidebar-category-master").click(function(){
		var categoryID = $(this).attr("data-category");
		$(this).parent().find(".sidebar-category-master").removeClass("active");
		$(this).addClass("active");
		$(this).parent().parent().find("table").removeClass("active");
		var target = $(this).parent().parent().find("#sidebar-category-" + categoryID).addClass('active');
		
		
		
	});
	$("#left-container .boxTitle").mouseover(function(){
		var list = $(this).parent().find(".subLst");
		if(!$(list).hasClass("selected")){
			console.log("not selected");
			$(this).addClass("activated");
			$(list).slideDown();
		}
	});
	$("#left-container .menuLst").mouseleave(function(){
		var list = $(this).find(".subLst");
		if(!$(list).hasClass("selected")){
			$("#left-container .menuLst .boxTitle").removeClass("activated");
			$(list).slideUp();
		}
	});
	
	$(".btn-user-class").click(function(){
		var href = $(this).attr('data-href');
		window.location=href;
	});
	

	$("#left-sidebar .parentNavi > a").bind('click', function(e){if (!actionFlag) {actionFlagUp();
	  		var target = $(this).attr("data-child-menu");
	  		var targetState = $("#child_" + target).css('display');
	  		console.log(target);
	  		if(targetState == "block"){
		  		$(this).css('color','');  		
		  		$("#left-sidebar #child_" + target).slideUp(250);
	  		}else{
		  		$("#left-sidebar .parentNavi a").css('color','');
		  		$(this).css('color','white');
		  		$("#left-sidebar .childNavi").slideUp(50);		
		  		$("#left-sidebar #child_" + target).slideDown(250);		  		
	  		}
	  		
		}
	});	
	
});

$(window).scroll(function(){
	fixHeader();
});

$(window).resize(function(){
	fixUI();
});

function checkTouchHover(){
	/* add a class to <html> */
	var isTouch = 'ontouchstart' in window;
	document.documentElement.className += isTouch? ' touch ':' no-touch ';	
}

function searchAction(keyword){
	window.location.href = "/search/" + keyword;
}

function toggleSearch(){
	var status = $("#bar-search").hasClass('toggled');

	if(status){
		dismiss();		
	}else{
		$("#bar-search").addClass("toggled");
		$("#top-notice").addClass('toggled-search-bar');
		$("#header").addClass('toggled-search-bar');		
		$("#uiBlock").addClass('toggled-search-bar');
		$("#dismiss").addClass('toggled-search-bar');
	}
	fixHeaderTagBox();	
}

function dismiss(){
	actionFlagUp();
	$( "#left-sidebar" ).animate({
	    'left': "-230px",
	  },260);
	  
	$( "#right-sidebar" ).animate({
	    'right': "-230px",
	  },260);	  
	  
	$("#headerSearchBox").css('display','');			  

	$("#bar-search").removeClass("toggled");

	$("#header").removeClass('toggled-search-bar');
	
	$("#uiBlock").attr('class','');
	$("#dismiss").attr('class','');
	$("#box-video").attr('class','');
	$("#box-video").attr('style','');
	$("#commons_player").attr('style','');	
	$(window).resize();
}

function toggleLeftContainer(){
	var stateContainer = $("#left-container").css("left");
	var containerState = $("#container").hasClass("two-rows");

	if(stateContainer == "-230px"){
		$("#left-container").animate({left: '0px'});
		if(!containerState){
			$("#center-container").animate({"margin-left": '230px'});
			
			setTimeout(function(){ fixUI(); }, 500);
		}
	}else{
		$("#left-container").animate({left: '-230px'});
		if(!containerState){
			$("#center-container").animate({"margin-left": ''});
			setTimeout(function(){ fixUI(); }, 500);
		}		
	}

}

function toggleLeftSidebar(){
	var stateSidebar = $("#left-sidebar").css("left");
	//console.log(stateSidebar); 
	if(stateSidebar == "-230px"){
		//dismiss();
		$("#left-sidebar").animate({left: '0px'});
		$("#uiBlock").addClass('toggled-left-sidebar');
		$("#dismiss").addClass("toggled-left-sidebar");		
	}else{
		dismiss();
	}

}

function toggleRightSidebar(){
	var stateSidebar = $("#right-sidebar").css("right");
	//console.log(stateSidebar);
	if(stateSidebar == "-230px"){
		//dismiss();
		$("#right-sidebar").animate({right: '0px'});
		$("#uiBlock").addClass('toggled-right-sidebar');
		$("#dismiss").addClass("toggled-right-sidebar");		
	}else{
		dismiss();
	}
}

function fixHeaderTagBox(){

	var themeColor = $.getMeta('name','theme-color');
	
	var tagBox = $("#bar-recommand-tags").width();
	var tagBox_label = $("#recommand-tags-label").outerWidth() + 10;

	$("#main-tag-list").width(tagBox - tagBox_label);
	
	var tagCnt = $("#main-tag-list ul li").length;
	var tagWidth = 0;
	$("#bar-recommand-tags #main-tag-list ul li a").each(function(key, val){
		tagWidth = $(this).width();
		//console.log(tagWidth);
	});
	
	var tagWidth = $("#main-tag-list ul li").width();

	$("#main-tag-list ul").width(tagCnt * tagWidth);
	
	
    setTimeout(function () {
	    $("#main-tag-list .scrollbar-inner").scrollbar();
        $("#main-tag-list").css('visibility','visible');
    }, 100);
}

function fixTopSliderPosts(){
	var options = {
		scrollx :false,
	}
	$("#slide-posts .scrollbar-inner").scrollbar(options);
}

function fixHeader(){
	var scrollPos = $(window).scrollTop();
	if(scrollPos > 30){
		$("#header").css("top","0px");
	}else{
		$("#header").css("top","");
	}
}

function fixUI(){
	var windowHeight = $(window).height();
	var headerHeight = $("#header").height();
	var leftContentInnerHeight = windowHeight - headerHeight;
	
	var showProgramPos = $("#btn-show-program").position();

	var themeColor = $.getMeta('name','theme-color');
	
	$("#left-sidebar .scrollbar-inner").css("height",windowHeight);
    setTimeout(function () {
	    $("#left-sidebar .scrollbar-inner").scrollbar();
        //$("#main-tag-list").css('visibility','visible');
    }, 100);
    
	//$("#left-sidebar").scrollbar();
	

	fixHeaderTagBox();
	fixBottomCategory();
	fixFooterLinks();
	/*
	$('#left-container .left-content').slimScroll({
	    color: themeColor,
	    size: '4px',
	    height: leftContentInnerHeight + 'px',
	    alwaysVisible: true,
	    touchScrollStep: 100
	});	
	*/
	//$('.postLst').masonry();
}

function fixFooterLinks(){
	var footerLinkWidth = 0
	$(".footer-middle .wrapper-links .links li").each(function(key, val){
		//console.log($(this).outerWidth());
		footerLinkWidth += $(this).outerWidth(true);
		//footerLinkWidth += $(this).css("margin-left");
	});
	
	footerLinkWidth = footerLinkWidth + 20;
	$(".footer-middle .wrapper-links .links").width(footerLinkWidth);
	
	var options = {
		"scrollx" : "simple",
		"autoUpdate" : true,
		"showArrows" : true
		
	}	
    setTimeout(function () {
	    $('.footer-middle .wrapper-links').scrollbar(options);
        //$("#main-tag-list").css('visibility','visible');
    }, 100);	
	
	
}

function fixBottomCategory(){ 
	$(".bottom_category .bottom-category-list ul").each(function(idx){
			var cnt =  $(this).find("li").size();
			var listWidth = cnt*119;
			console.log(listWidth);
			$(this).css('width',listWidth);
			$(this).parent().scrollbar();
	});
}

function pageRefresh(options){

	if(options != undefined){
		setTimeout(function(){ 
			Cookies.set('message',options.message);
			Cookies.set('color',options.color);		
			location.reload();
			
		}, options.time);		
	}else{
		location.reload();
	}

}