(function($){
	

	$.tag_strip = function(html)
	{
	   var tmp = document.createElement("DIV");
	   tmp.innerHTML = html; 
	   return tmp.textContent || tmp.innerText || "";
	} 

	$.GET = function(name){ // Don't Confuse between php = $_GET '[]' this $.GET '()' 
		return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null;
	}

	/* testing */
  	$.val_email = function(email) {
		return !/^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test( email );
	}
	
	$.val_password = function(password, password_confirm){ // 6~15, a-A, 특수문자
		var res = false;
		
		if(password == password_confirm){
		
			if(!password.match(/^.*(?=^.{6,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[ !@#$%^&*\-\[\] ]).*$/)){
				return true; // error = true
			}else{
				return false; // error = false
			}
			
		}else{
			return false; // error = false
		}
		

	}
	$.convertSpecialChar = function(string){
		return string.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");		
	}
	/* testing */	
	$.val_Special = function(str){
	     var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?~_";
		   for (var i = 0; i < str.length; i++) {
			if (iChars.indexOf(str.charAt(i)) != -1) {
				return false;
			}
		  }	
	}

	$.openFlashMessage = function(message, color, time){
		var flashHTML = "<div class='flashBox'><div class='flashBackground'></div><div class='flashMessage'>" + message + "</div></div>";
		$("body").append(flashHTML);
		setTimeout(function(){ 
			$(".flashBox").fadeOut(250); 
			$(".flashBox").delay(350).remove(); 
		}, 1000);
	}

	/* testing */	
	$.cnt_special = function(str){
     var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?~_";
     var cntSpecial = 0;

	   for (var i = 0; i < str.length; i++) {
			if (iChars.indexOf(str.charAt(i)) != -1) {
				cntSpecial++;
			}
		  }	
	  return cntSpecial;
	}
	
	/* testing */
	$.val_Num = function(num){
      var state = !isNaN(num);
	  return state;				
	}

	$.getErrMsg = function(error_code){
		var _token  = $("meta[property='moa:token']").attr("content");		
		$.ajax({
		          type: "post",
		          url: "/getErrMsg",
		          dataType: 'json',
		          data: {_token:_token, error_code:error_code},
		          success: function(data){
				  		return data;
				  		
				  		//showMessage(data.comment);
		          },
		          error: function(request,status,error){
		               //alert("Sorry Something wrong.");
		          },
		          done: function(){	
					  
		          }
	    });
		
	}

	$.getMeta = function(type, meta){
		
		return $("meta[" + type + "='" + meta + "']").attr("content");
	}

	/* Show up POPUP Center care about dual Screen */
	$.popupCenter = function(url, title, w, h) { // It's from StackFlow but can't remember Address Sorry.
	    // Fixes dual-screen position                         Most browsers      Firefox
	    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
	    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
	
	    width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
	    height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
	
	    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
	    var top = ((height / 2) - (h / 2)) + dualScreenTop;
	    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
	
	    // Puts focus on the newWindow
	    if (window.focus) {
	        newWindow.focus();
	    }
	}

	$.codeToHtml = function(code){
			code = code.replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
				.replace(/\//g, '&#x2F;');
				
			return code;
	}
	$.fn.tagDataListing = function(tags){

		var tagClearBox = this;
		
		if(tags != ""){
		    var tag = tags.split(",");
				var tagHtml = '';
				$.each(tag, function(idx, val){
					tagHtml += '<li data-val="' + val + '">';
					tagHtml += '<div class="tagRemove leftBox"><i class="icon-remove-circle"></i></div>';
					tagHtml += '<div class="tagLabel leftBox">' + val + '</div>';
					tagHtml += '<div class="clear"></div>';
					tagHtml += '</li>';									
				});
				
				$(tagHtml).insertBefore(tagClearBox);
		    $(".tagRemove").off().click(function(){
			    $(this).parent('li').remove();  
		    });	
			
		}
	}

	$.fn.tagDataGenerator = function()
	{
		var tagObjectBox = this;

		var tags = '';
		var size = tagObjectBox.children('ul').children('li').size();
	
		tagObjectBox.children('ul').children('li').each(function( index ) {
			
			if(index < size - 1){		
			  	tags += $(this).attr('data-val') + ",";
			}else{
				tags += $(this).attr('data-val');
				
			}
	
		});
		//console.log(tags);
		
		return tags;
	}

	$.rawurlencode = function(str){
		  //       discuss at: http://phpjs.org/functions/rawurlencode/
		  //      original by: Brett Zamir (http://brett-zamir.me)
		  //         input by: travc
		  //         input by: Brett Zamir (http://brett-zamir.me)
		  //         input by: Michael Grier
		  //         input by: Ratheous
		  //      bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		  //      bugfixed by: Brett Zamir (http://brett-zamir.me)
		  //      bugfixed by: Joris
		  // reimplemented by: Brett Zamir (http://brett-zamir.me)
		  // reimplemented by: Brett Zamir (http://brett-zamir.me)
		  //             note: This reflects PHP 5.3/6.0+ behavior
		  //             note: Please be aware that this function expects to encode into UTF-8 encoded strings, as found on
		  //             note: pages served as UTF-8
		  //        example 1: rawurlencode('Kevin van Zonneveld!');
		  //        returns 1: 'Kevin%20van%20Zonneveld%21'
		  //        example 2: rawurlencode('http://kevin.vanzonneveld.net/');
		  //        returns 2: 'http%3A%2F%2Fkevin.vanzonneveld.net%2F'
		  //        example 3: rawurlencode('http://www.google.nl/search?q=php.js&ie=utf-8&oe=utf-8&aq=t&rls=com.ubuntu:en-US:unofficial&client=firefox-a');
		  //        returns 3: 'http%3A%2F%2Fwww.google.nl%2Fsearch%3Fq%3Dphp.js%26ie%3Dutf-8%26oe%3Dutf-8%26aq%3Dt%26rls%3Dcom.ubuntu%3Aen-US%3Aunofficial%26client%3Dfirefox-a'
		
		  str = (str + '')
		    .toString()
		  
		  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
		  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
		  return encodeURIComponent(str)
		    .replace(/!/g, '%21')
		    .replace(/'/g, '%27')
		    .replace(/\(/g, '%28')
		    .
		  replace(/\)/g, '%29')
		    .replace(/\*/g, '%2A')		
		
	}
	
	$.timeConverter = function(UNIX_timestamp){
	  var a = new Date(UNIX_timestamp * 1000);
	  var months = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'];
	  var year = a.getFullYear();
	  var month = months[a.getMonth()];
	  var date = a.getDate();
	  var hour = a.getHours();
	  var min = a.getMinutes();
	  var sec = a.getSeconds();
	  var time = year + ' ' + month + ' ' + date + ' ' + hour + ':' + min + ':' + sec ;
	  return time;
	}
	$.toHHMMSS = function (time) {
	    var sec_num = parseInt(time, 10); // don't forget the second param
	    var hours   = Math.floor(sec_num / 3600);
	    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
	    var seconds = sec_num - (hours * 3600) - (minutes * 60);
	
	    if (hours   < 10) {hours   = "0"+hours;}
	    if (minutes < 10) {minutes = "0"+minutes;}
	    if (seconds < 10) {seconds = "0"+seconds;}
	    return hours+':'+minutes+':'+seconds;
	}
	
})(jQuery);