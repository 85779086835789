actionFlag = false;
actionFlag_timer = 500;	
function actionFlagUp(){actionFlag = true; setTimeout(function(){ actionFlag = false;}, actionFlag_timer);}

$("document").ready(function(){
	$(".btn-share").bind('click touchstart',function(){if (!actionFlag) {actionFlagUp();
		bindShare(this.id);
	}});
	$(".btn-side-share").bind('click touchstart',function(){if (!actionFlag) {actionFlagUp();
		bindShare(this.id);
		
	}});	
	
});

function bindShare(shareTo){
	switch(shareTo){
		case "share-facebook":
			shareFacebook();
		break;
		case "share-twitter":
			shareTwitter();
		break;
		case "share-google":
			shareGoogle();
		break;
		case "share-kakaostory":
			shareKakaotalk();
		break;
		case "share-band":
			shareBand();
		break;
		case "share-naver":
			shareNaver();
		break;															
		case "share-mail":
			shareEmail();
		break;
		case "share-link":
			shareLink();
		break;				
	}	
}


function shareFacebook(){
	var url = document.URL;
	var title = $.getMeta('name','subject');
	var desc = $.getMeta('name','description');
	var targetUrl = "http://www.facebook.com/sharer/sharer.php?u=" + url + "&t=" + title;
	var popOption = "width=370, height=360, resizable=no, scrollbars=no, status=no;";    //팝업창 옵션(optoin)
	window.open(targetUrl,"",popOption);
}

function shareTwitter(){
	var url = encodeURI(document.URL);
	var post_id = $.getMeta('name','post:id');
	
	var url = location.protocol + "//" + window.location.hostname + "/post/" + post_id;
	var title = $.getMeta('name','subject');
	var desc = $.getMeta('name','description');
	var targetUrl = "https://twitter.com/intent/tweet?url=" + url + "&text=" + title + "&hashtags=#ssu";
	
	var popOption = "width=370, height=360, resizable=no, scrollbars=no, status=no;";    //팝업창 옵션(optoin)
	window.open(targetUrl,"",popOption);
}

function shareGoogle(){
	var url = encodeURI(document.URL);
	var targetUrl = "https://plus.google.com/share?url=" + url;
	var popOption = "width=520, height=360, resizable=no, scrollbars=no, status=no;";    //팝업창 옵션(optoin)
	window.open(targetUrl,"",popOption);
}

function shareKakaotalk(){
	var url = document.URL;
	var targetUrl = "https://story.kakao.com/share?url=" + url;
	var popOption = "width=370, height=420, resizable=no, scrollbars=no, status=no;";    //팝업창 옵션(optoin)
	window.open(targetUrl,"",popOption);
}

function shareBand(){
	var url = document.URL;	
	var title = $.getMeta('name','subject');
	var desc = $.getMeta('name','description');
	var urlBody = title + "&" + url;
	urlBody = $.rawurlencode(urlBody);
	console.log(urlBody);
	window.open("http://www.band.us/plugin/share?body=" + urlBody, "band_pop", "width=700,height=550,scrollbars=no,resizable=no");
}

function shareNaver(){
	var url = document.URL;
	var title = $.getMeta('name','subject');
	var desc = $.getMeta('name','description');
	var targetUrl = "http://blog.naver.com/openapi/share?url=" + url + "&t=" + title;
	var popOption = "width=370, height=360, resizable=no, scrollbars=no, status=no;";    //팝업창 옵션(optoin)
	window.open(targetUrl,"",popOption);
}
function shareEmail(){
	var url = document.URL;
	var title = $.getMeta('name','subject');
	var desc = $.getMeta('name','description');
	window.location.href = "mailto:?subject=" + title + "&body=" + title + " - " + url;
	//href="mailto:?subject=I wanted you to see this site&amp;body=" + url + " title="Share by Email"	
}

function shareLink(){
	var url = document.URL;
	prompt('CTL + C 로 복사하세요 :', window.location);
}