$("document").ready(function(){
		$('#tip-following').tipso({
			position: 'right',
			background: '#f4fdff',
			color: '#4d4d4d',
			size: 'default',
			width: 280,
			showArrow : false,
			content : '나 또는 특정 대상이 팔로우하는 사람을 뜻합니다.'
		});
		
		$('#tip-follower').tipso({
			position: 'right',
			background: '#f4fdff',
			color: '#4d4d4d',
			size: 'default',
			width: 280,
			showArrow : false,
			content : '나 또는 특정 대상을 팔로우하는 사람을 뜻합니다.'
		});		
		$('#tip-contents-manager').tipso({
			position: 'right',
			background: '#f4fdff',
			color: '#4d4d4d',
			size: 'default',
			width: 310,
			showArrow : false,
			content : '동영상, 오디오, 문서, 글을 자유롭게 등록할 수 있습니다.'
		});	

		$('#tip-course-manager').tipso({
			position: 'right',
			background: '#f4fdff',
			color: '#4d4d4d',
			size: 'default',
			width: 330,
			showArrow : false,
			content : '강좌란 누구나 만들고 공유할 수 있는 콘텐츠 모음입니다. 본인만의 지식을 다른 사용자에게 공유해 보세요. <br/><br/> 강좌 기본 정보를 등록 후 등록한 콘텐츠를 선별하면 됩니다.'
		});

		$('#tip-tags').tipso({
			position: 'right',
			background: '#f4fdff',
			color: '#4d4d4d',
			size: 'default',
			width: 450,
			showArrow : false,
			content : '태그란 메타데이터 태그로 본 시스템에서 특정한 주제나 내용을 담은 내용을 쉽게 찾을<br/> 수 있도록 하기 위한 검색어입니다.'
		});	
			
		
});